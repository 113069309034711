import React from 'react';
const Devlog = () =>{
  return (
    <div>
      <h3>devlog</h3>
      <div>
        <p>set on <a href="https://www.youtube.com/watch?v=H0iwGVKfe0k&ab_channel=DustRadio" target="_blank" rel="noreferrer"> Dust</a></p>
        <img src="./images/dust.png" alt="" />
      </div>
    </div>
  );
}
export default Devlog;
